// offline get
var menus = localStorage.getItem("menus")
  ? JSON.parse(localStorage.getItem("menus"))
  : null;

if (menus == null) {
  // online get
  // get menus
  myApi({
    url: "get-all-menus",
    method: "get",
  })
    .then((response) => {
      localStorage.setItem("menus", JSON.stringify(response.data.data));
      /* console.log('window.menus =  response.data');
    console.log(window.menus); */
    })
    .catch((error) => {});
}

var formatedMenus = [];
var permissions = JSON.parse(localStorage.getItem("menus"));

// add separator
formatedMenus.push({
  header: "Gestion Globale",

  action: "read",
  resource: "ACL",
});
for (let index = 0; index < permissions.length; index++) {
  const department = permissions[index];
  const modules = permissions[index].modules;

  if (department.route == "bank") {
    // add separator
    formatedMenus.push({
      header: "Modules Principaux",
      action: "read",
      resource: "ACL",
    });
  } else if (department.route == "repporting") {
    // add separator
    formatedMenus.push({
      header: "Outils d'Analyse",
      action: "read",
      resource: "ACL",
    });
  }

  var modulesFormated = [];
  for (let module_index = 0; module_index < modules.length; module_index++) {
    const module = modules[module_index];
    if (module.module != "") {
      modulesFormated.push({
        title: module.module,
        route: module.ressource,
        // acl: {
        action: "read",
        resource: module.ressource,
        // },
      });
    }
  }

  //
  if (department.name != "") {
    formatedMenus.push({
      title: department.name,
      icon: department.icon,
      // acl: {
      action: "read",
      resource: department.route,
      // },
      // tag: '2',
      tagVariant: "light-warning",
      children: modulesFormated,
    });
  }
}

// export
export default formatedMenus;
