<template>
  <b-nav-item-dropdown v-if="notifications" class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="notifications.length" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary"> {{ notifications.length }} Nouveaux </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar v-once :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
      tagname="li">
      <!-- Account Notification -->
      <template>
        <b-link v-for="notification in notifications">
          <b-media>
            <template #aside>
              <b-avatar size="32" variant="light-primary">
                <feather-icon size="24" icon="ServerIcon" />
              </b-avatar>
            </template>

            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.object }}
              </span>
            </p>
            <small class="notification-text">{{ notification.content }}</small>
          </b-media>
        </b-link>
      </template>

      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          Notifications du Système
        </h6>
        <b-form-checkbox :checked="true" switch />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link v-for="notification in systemNotifications" :key="notification.subtitle">
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="notification.type">
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button v-ripple.400=" 'rgba(255, 255, 255, 0.15)' " variant="primary" block to="/notification">Lire les
        notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      newMessages: "",
      notifications: [],
      systemNotifications: []
    };
  },
  mounted() {
    // data
    myApi
      .get("notification")
      .then((response) => {
        // default action
        console.log('response.data.data');
        console.log(response.data.data);
        this.notifications = response.data.data;/* 
        this.newMessages = response.data.data.newMessages; 
        this.systemNotifications = response.data.data.systemNotifications; */

      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  },
  setup() {

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style></style>
